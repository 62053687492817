import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'
import HomeButton from '../UI/Buttons/HomeButton'

const KeyFacts = () => {

  const data = useStaticQuery(graphql`
    query ComponentKeyFactsQuery {
      allContentfulComponentKeyFacts {
        nodes {
          title
          content {
            content
          }
        }
      }
    }
  `)

  const items = data.allContentfulComponentKeyFacts.nodes.reverse()
  
  return (
    <Wrapper>
      <h2>Key Facts</h2>

      <Table>
        <tbody>
          {items.map((item, index) => 
            <tr key={index}>
              <Title>{item.title}</Title>
              <Content>{item.content.content}</Content>
            </tr>
          )}
        </tbody>
      </Table>

      <BtnWrapper id="registerToInvest">
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="S1_PL_Trust_Information_Summary_2024.pdf"
        >
          <HomeButton
            height={BUTTON_SIZE.HEIGHT_L}
            padding={BUTTON_SIZE.PADDING_L}
            fontSize={BUTTON_SIZE.FONT_SIZE_L}
            background={BUTTON_COLOR.GREEN}
            backgroundHover={BUTTON_COLOR.GREEN_HOVER}
            color={BUTTON_COLOR.TEXT_COLOR_WHITE}
            text='Download Summary (PDF)'
            handleClick={() => {}}
          />
        </a>
      </BtnWrapper>

    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    font-size: 40px;
    margin: 80px auto;
  }
`
const Table = styled.table`
  border: 1px solid rgb(200 200 200);
  border-collapse: collapse;
`
const Title = styled.td`
  font-size: 20px;
  font-family: QuicksandBold;
  text-align: left;
  width: 410px;
  padding: 16px;
  background: rgb(242 244 246);
  border: 1px solid rgb(200 200 200);
  @media screen and (max-width: 991px) {
			width: 30vw;
		}


`
const Content = styled.td`
  font-size: 16px;
  text-align: left;
  max-width: 548px;
  padding: 16px;
  border: 1px solid rgb(200 200 200);
`
const BtnWrapper = styled.div`
  margin: 80px auto 0px;
`

export default KeyFacts