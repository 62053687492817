import React from 'react'
import styled from 'styled-components'

import ContentContainer from '../UI/ContentContainer'
import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'
import HomeButton from '../UI/Buttons/HomeButton';
import InvestCarousel from './InvestCarousel';

const InvestInPeople = () => {

  const handleClick = () => document.getElementById('learnMore').scrollIntoView()

	return (
		<ContentContainer>
			<Main>
				<MainInvestInPeople>
					<h1 >Invest In <span className="greenHeader">People</span></h1>
					<p>Consumer lending is an asset class traditionally controlled by banks and is among the most profitable parts of their retail lending operations. We specialise in consumer lending to Australians and we also provide access to those loans as an investment for a minimum commitment of $100,000.</p>
					<div className="btnWrapper">
						<HomeButton
								height={BUTTON_SIZE.HEIGHT_L}
								padding={BUTTON_SIZE.PADDING_L}
								fontSize={BUTTON_SIZE.FONT_SIZE_L}
								background={BUTTON_COLOR.GREEN}
								backgroundHover={BUTTON_COLOR.GREEN_HOVER}
								color={BUTTON_COLOR.TEXT_COLOR_WHITE}
								text='Learn More'
								handleClick={handleClick}
							/>
					</div>
				</MainInvestInPeople>
				<MainImg>
					<InvestCarousel />
				</MainImg>
			</Main>
		</ContentContainer>
	)
}

const Main = styled.div`
	display: flex;	
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin: 0 auto;
	padding: 0 30px;
	@media screen and (max-width: 991px) {
	}
	@media screen and (max-width: 768px) {
		flex-direction: column;
		justify-content: center;
	}
`
const MainInvestInPeople = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	max-width: 480px;
	h1 {
		margin: 10px 0;
		@media screen and (max-width: 991px) {
			font-size: 42px;
		};
		@media screen and (max-width: 768px) {
		text-align: center;
		margin: 0 auto;
		};
	};
	.greenHeader {
		color: rgb(29 188 110);
	};
	>p {
		font-size: 20px;
		@media screen and (max-width: 768px) {
			text-align: center;
		};

	};
	.btnWrapper {
		padding: 10px 0;
		@media screen and (max-width: 768px) {
			margin: 0 auto;
		};

	}
`

const MainImg = styled.div`
	max-width: 445px;
	overflow: hidden;
	img {
		border-radius: 50%;
	}
	@media screen and (max-width: 991px) {
		max-width: 396px;
	};

	@media screen and (max-width: 768px) {
		margin: 30px 0;


	};
	@media screen and (max-width: 480px) {
		max-width: 100%;
	};

`

export default InvestInPeople
