import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import ContentContainer from '../../components/UI/ContentContainer'
import RegisterYourInterest from './RegisterYourInterest'
import InvestCard from './InvestCard'
import InvestPageFaqs from './InvestPageFaqs'
import KeyFacts from './KeyFacts'
import HeaderAndButtonWithGreenBackground from '../blocks/HeaderAndButtonWithGreenBackground'

const ProvenAssetClass = () => {
  
  const handleClick = () => document.getElementById('registerToInvest').scrollIntoView()
  const queriedProvenAssetClass = useStaticQuery(graphql`
    query ProvenAssetClass {
      contentfulComponentTopicDescAndImgContainer (
        page: { eq: "Invest" }
      ) {
        imageWithContent {
          title
          description {
            childMarkdownRemark {
              html
            }
          }
          imageOnLeft
          image {
            gatsbyImageData(placeholder:NONE width: 300)
            title
          }
        }
      }
    }
  `)
  const { imageWithContent } = queriedProvenAssetClass.contentfulComponentTopicDescAndImgContainer
  return (
		<ContentContainer>
      <Wrapper id="asset-classes">
        {imageWithContent && imageWithContent.map((card, i) =>
          <InvestCard
            title={card.title}
            img={card.image}
            imgIsRight={!card.imageOnLeft}
            key={i}
          >
            <div 
              dangerouslySetInnerHTML={{
                __html: card.description.childMarkdownRemark.html,
              }}
            />
          </InvestCard>
        )}
        <Note>
          The information contained on this website has been provided as general advice only and does not take into account your objectives, financial situation or needs. Before investing, you should consult your financial advisor to consider whether the product is appropriate for your circumstances.
        </Note>
      </Wrapper>
      <HeaderAndButtonWithGreenBackground 
        handleClick={handleClick}
        header={'Ready To Invest?'}
        buttonText={'Register Your Interest'}
      />
      <KeyFacts />
      <RegisterYourInterest />
      <InvestPageFaqs />

    </ContentContainer>
  )
}

const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Note = styled.div`
  max-width: 1000px;
  margin: 20px 0 100px 0;
  font-size: 14px;
`
export default ProvenAssetClass